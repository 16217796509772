.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8%; /* Set the height based on your design */
    margin: 20px 16px; /* Adjust the margin as needed */
  }
  
  .logo-image {
    width: 100%; /* Set the width as needed */
    height: auto;
  }

  .welcome-message {
    margin-left: auto; /* This pushes the welcome message to the right */
    padding: 16px;
    color: #000; /* Adjust the color as needed */
    font-size: 16px;
    font-weight: bold;
    /* display: inline-block; Ensure the element behaves like an inline block */
    float: right; /* Float the element to the right */
  }