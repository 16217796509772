.image-background { 
    background-image: url(https://images.pexels.com/photos/290275/pexels-photo-290275.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);
    background-position: center;
    background-size: cover;
    min-height: 100vh;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0px 100px;
  }

  .form-container form {
    width: 100%;
    /* max-width: 300px; Adjust the width as needed */
  }